/**
 * Created by workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 09/05/24
 * Time: 1:09 PM
 * Project: W-CRM
 */
import React, {memo, useMemo} from 'react';
import "./style.css"
import {useSelector} from "react-redux";
import {profileState} from "../../redux/profile/profileReducer";
import Alert from "../Alert/Alert";
import {adminRole, ownerRole, staffRole} from "../../utils/constants";
import {datediff, parseDate} from "../../utils/common";

export const SubscriptionCheck = memo(() => {
    const {profile} = useSelector(profileState)

    const subscriptionMapping = useMemo(() => {
        return profile.data?.userassignedsubscriptionmapping
    }, [profile?.data]);

    const activeSubDays = useMemo(() => {
        let leftDaysFromToday = 0;
        if (subscriptionMapping?.user_owned_subscription_mapping) {
            leftDaysFromToday = datediff(
                new Date(),
                parseDate(subscriptionMapping?.user_owned_subscription_mapping["end_date"])
            );
        }

        return leftDaysFromToday
    }, [profile.data]);

    const getAlertText = () => {
        let myRole = profile?.data?.userorgrolemapping?.role?.name
        if (myRole === ownerRole) {
            return "Your subscription has expired. Please renew now to continue accessing premium benefits."
        } else {
            return "Your organisation's subscription has expired. Please contact your administrator to renew and continue accessing premium benefits."
        }
    }


    return (
        <>{(activeSubDays < 0 && [staffRole, adminRole, ownerRole].includes(profile?.data?.userorgrolemapping?.role?.name)) &&
            <div className={"subscription-alert-container"}>
                <Alert type={"warning"} title={"Subscription expired"} description={getAlertText()}/>
            </div>
        }
        </>
    );
})