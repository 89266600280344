import {createSlice, current} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {endPoints} from "../../api/endpoints";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";

/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 4/20/2022
 * Time: 4:09 PM
 * Project: w_crm
 */

const initialEFormState = {
    get: {
        isLoading: false, data: [], error: null,
    }, create: {
        isLoading: false, isCreated: false, error: null, data: null,
    }, retrieve: {
        isLoading: false, data: null, error: null,
    }, save: {
        isLoading: false, isUpdated: false, error: null,
    },

     autosave: {
        isLoading: false, isUpdated: false, error: null,
    },

    remove: {
        isLoading: false, isDeleted: false, error: null,
    }, clientInvite: {
        isLoading: false, data: null, error: null,
    }, response: {
        isLoading: false, data: [], error: null,
    }, assigned: {
        isLoading: false, data: [], error: null,
    }, unAssigned: {
        isLoading: false, data: [], error: null,
    }, assign: {
        isLoading: false, data: null, error: null,
    }, unassign: {
        isLoading: false, data: null, error: null,
    }, download: {
        isLoading: false, data: null, error: null,
    }, mail: {
        isLoading: false, data: null, error: null,
    }, events: {
        isLoading: false, data: null, error: null,
    }, updateResponse: {
        isLoading: false, data: null, error: null,
    }, responseOtp: {
        isLoading: false, data: null, error: null,
    },

    pdfGenerationStatus: {
        isLoading: false, data: null, error: null,
    },
};

export const EFormSlice = createSlice({
    name: "e_form", initialState: {
        ...initialEFormState,
    }, reducers: {
        createEFormRequesting: (state, action) => {
            state.create.isLoading = true;
        }, createEFormSuccess: (state, action) => {
            state.create.isLoading = false;
            state.create.error = null;
            state.create.isCreated = true;
            state.create.data = action.payload.data;
        }, createEFormError: (state, action) => {
            state.create.isLoading = false;
            state.create.isCreated = false;
            state.create.error = action.payload;
        }, createEFormReset: (state, action) => {
            state.create = initialEFormState.create;
        }, getEFormRequesting: (state, action) => {
            state.get.isLoading = true;
        }, getEFormSuccess: (state, action) => {
            state.get.isLoading = false;
            state.get.data = action.payload.data;
            state.get.error = null;
        }, getEFormError: (state, action) => {
            state.get.isLoading = false;
            state.get.error = action.payload;
        }, getEFormReset: (state, action) => {
            state.get = initialEFormState.get;
        },

        responseEFormRequesting: (state, action) => {
            state.response.isLoading = true;
        },

        responseEFormSuccess: (state, action) => {
            state.response.isLoading = false;
            state.response.data = action.payload.data;
            state.response.error = null;
        },

        responseEFormUpdate: (state, action) => {
            const {response} = current(state);
            const newList = action.payload;
            const newItemsMap = new Map(newList.map(item => [item.id, item]));


            const updatedResults = response.data.results.map(item =>
                newItemsMap.has(item.id)
                    ? {...item, ...newItemsMap.get(item.id)}
                    : item
            );

            state.response.data = {...response, results: updatedResults};
        },


        responseEFormError: (state, action) => {
            state.response.isLoading = false;
            state.response.error = action.payload;
        }, responseEFormReset: (state, action) => {
            state.response = initialEFormState.response;
        },

        retrieveEFormRequesting: (state, action) => {
            state.retrieve.isLoading = true;
        }, retrieveEFormSuccess: (state, action) => {
            state.retrieve.isLoading = false;
            state.retrieve.data = action.payload.data;
            state.retrieve.error = null;
        }, retrieveEFormError: (state, action) => {
            state.retrieve.isLoading = false;
            state.retrieve.error = action.payload;
        }, retrieveEFormReset: (state, action) => {
            state.retrieve = initialEFormState.retrieve;
        },

        saveEFormRequesting: (state, action) => {
            state.save.isLoading = true;
            state.save.error = null;
        }, saveEFormSuccess: (state, action) => {
            state.save.isLoading = false;
            state.save.isUpdated = true;
            state.save.error = null;
        }, saveEFormError: (state, action) => {
            state.save.isLoading = false;
            state.save.isUpdated = false;
            state.save.error = action.payload;
        }, saveEFormReset: (state, action) => {
            state.save = initialEFormState.save;
        },


         autoSaveEFormRequesting: (state, action) => {
            state.autosave.isLoading = true;
            state.autosave.error = null;
        }, autoSaveEFormSuccess: (state, action) => {
            state.autosave.isLoading = false;
            state.autosave.isUpdated = true;
            state.autosave.error = null;
        }, autoSaveEFormError: (state, action) => {
            state.autosave.isLoading = false;
            state.autosave.isUpdated = false;
            state.autosave.error = action.payload;
        }, autoSaveEFormReset: (state, action) => {
            state.autosave = initialEFormState.autosave;
        },

        removeEFormRequesting: (state, action) => {
            state.remove.isLoading = true;
        }, removeEFormSuccess: (state, action) => {
            state.remove.isLoading = false;
            state.remove.isDeleted = true;
            state.remove.error = null;
        }, removeEFormError: (state, action) => {
            state.remove.isLoading = false;
            state.remove.isDeleted = false;
            state.remove.error = action.payload;
        }, removeEFormReset: (state, action) => {
            state.remove = initialEFormState.remove;
        },

        clientInviteEFormRequesting: (state, action) => {
            state.clientInvite.isLoading = true;
        }, clientInviteEFormSuccess: (state, action) => {
            state.clientInvite.isLoading = false;
            state.clientInvite.error = null;
            state.clientInvite.data = action.payload.data;
        }, clientInviteEFormError: (state, action) => {
            state.clientInvite.isLoading = false;
            state.clientInvite.data = null;
            state.clientInvite.error = action.payload;
        }, clientInviteEFormReset: (state, action) => {
            state.clientInvite = initialEFormState.clientInvite;
        }, getUnassignedRequesting: (state, action) => {
            state.unAssigned.isLoading = true;
        }, getUnassignedSuccess: (state, action) => {
            state.unAssigned.isLoading = false;
            state.unAssigned.data = action.payload.data;
            state.unAssigned.error = null;
        }, getUnassignedError: (state, action) => {
            state.unAssigned.isLoading = false;
            state.unAssigned.error = action.payload;
        }, getUnassignedReset: (state, action) => {
            state.unAssigned = initialEFormState.unAssigned;
        },

        getAssignedRequesting: (state, action) => {
            state.assigned.isLoading = true;
        }, getAssignedSuccess: (state, action) => {
            state.assigned.isLoading = false;
            state.assigned.data = action.payload.data;
            state.assigned.error = null;
        }, getAssignedError: (state, action) => {
            state.assigned.isLoading = false;
            state.assigned.error = action.payload;
        }, getAssignedReset: (state, action) => {
            state.assigned = initialEFormState.assigned;
        },

        getEventsRequesting: (state, action) => {
            state.events.isLoading = true;
        }, getEventsSuccess: (state, action) => {
            state.events.isLoading = false;
            state.events.data = action.payload.data;
            state.events.error = null;
        }, getEventsError: (state, action) => {
            state.events.isLoading = false;
            state.events.error = action.payload;
        }, getEventsReset: (state, action) => {
            state.events = initialEFormState.events;
        },

        assignRequesting: (state, action) => {
            state.assign.isLoading = true;
        }, assignSuccess: (state, action) => {
            state.assign.isLoading = false;
            state.assign.data = action.payload.data;
            state.assign.error = null;
        }, assignError: (state, action) => {
            state.assign.isLoading = false;
            state.assign.error = action.payload;
        }, assignReset: (state, action) => {
            state.assign = initialEFormState.assign;
        },

        unAssignRequesting: (state, action) => {
            state.unassign.isLoading = true;
        }, unAssignSuccess: (state, action) => {
            state.unassign.isLoading = false;
            state.unassign.data = true;
            state.unassign.error = null;
        }, unAssignError: (state, action) => {
            state.unassign.isLoading = false;
            state.unassign.error = action.payload;
        }, unAssignReset: (state, action) => {
            state.unassign = initialEFormState.unassign;
        },

        downloadRequesting: (state, action) => {
            state.download.isLoading = true;
        }, downloadSuccess: (state, action) => {
            state.download.isLoading = false;
            state.download.data = action.payload.data;
            state.download.error = null;
        }, downloadError: (state, action) => {
            state.download.isLoading = false;
            state.download.error = action.payload;
        }, downloadReset: (state, action) => {
            state.download = initialEFormState.download;
        },

        mailEformResponseRequesting: (state, action) => {
            state.mail.isLoading = true;
        }, mailEformResponseSuccess: (state, action) => {
            state.mail.isLoading = false;
            state.mail.data = action.payload.data;
            state.mail.error = null;
        }, mailEformResponseError: (state, action) => {
            state.mail.isLoading = false;
            state.mail.error = action.payload;
        }, mailEformResponseReset: (state, action) => {
            state.mail = initialEFormState.mail;
        },

        updateResponseRequesting: (state, action) => {
            state.updateResponse.isLoading = true;
        }, updateResponseSuccess: (state, action) => {
            state.updateResponse.isLoading = false;
            state.updateResponse.data = action.payload.data;
            state.updateResponse.error = null;
        }, updateResponseError: (state, action) => {
            state.updateResponse.isLoading = false;
            state.updateResponse.error = action.payload;
        }, updateResponseReset: (state, action) => {
            state.updateResponse = initialEFormState.updateResponse;
        },

        responseOtpRequesting: (state, action) => {
            state.responseOtp.isLoading = true;
        }, responseOtpSuccess: (state, action) => {
            state.responseOtp.isLoading = false;
            state.responseOtp.data = action.payload.data;
            state.responseOtp.error = null;
        }, responseOtpError: (state, action) => {
            state.responseOtp.isLoading = false;
            state.responseOtp.error = action.payload;
        }, responseOtpReset: (state, action) => {
            state.responseOtp = initialEFormState.responseOtp;
        },

        pdfGenerationStatusRequesting: (state, action) => {
            state.pdfGenerationStatus.isLoading = true;
        }, pdfGenerationStatusSuccess: (state, action) => {
            state.pdfGenerationStatus.isLoading = false;
            state.pdfGenerationStatus.data = action.payload.data;
            state.pdfGenerationStatus.error = null;
        }, pdfGenerationStatusError: (state, action) => {
            state.pdfGenerationStatus.isLoading = false;
            state.pdfGenerationStatus.error = action.payload;
        }, pdfGenerationStatusReset: (state, action) => {
            state.pdfGenerationStatus = initialEFormState.pdfGenerationStatus;
        },

        EformMasterReset: () => initialEFormState,
    },
});

export const CreateEFormApi = (data) => async (dispatch) => {
    dispatch(createEFormRequesting());
    apiService
        .post(endPoints.e_form.create, data)
        .then((response) => {
            dispatch(createEFormSuccess(response.data));
        })
        .catch((error) => {
            dispatch(createEFormError(formatAxiosErrorMessage(error)));
        });
};

export const SaveEFormApi = (data) => async (dispatch) => {
    dispatch(saveEFormRequesting());
    apiService
        .patch(`${endPoints.e_form.create}${data.id}/`, data)
        .then((response) => {
            dispatch(saveEFormSuccess());
        })
        .catch((error) => {
            dispatch(saveEFormError(formatAxiosErrorMessage(error)));
        });
};


export const AutoSaveEFormApi = (data) => async (dispatch) => {
    dispatch(autoSaveEFormRequesting());
    apiService
        .patch(`${endPoints.e_form.create}${data.id}/`, data)
        .then((response) => {
            dispatch(autoSaveEFormSuccess());
        })
        .catch((error) => {
            dispatch(autoSaveEFormError(formatAxiosErrorMessage(error)));
        });
};

export const GetEFormApi = (query) => async (dispatch) => {
    dispatch(getEFormRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.e_form.create}${stringQuery}`, {})
        .then((response) => {
            dispatch(getEFormSuccess(response.data));
        })
        .catch((error) => dispatch(getEFormError(formatAxiosErrorMessage(error))));
};

export const RetrieveEFormApi = (id) => async (dispatch) => {
    dispatch(retrieveEFormRequesting());
    apiService
        .get(`${endPoints.e_form.create}${id}/`, {})
        .then((response) => {
            dispatch(retrieveEFormSuccess(response.data));
        })
        .catch((error) => dispatch(retrieveEFormError(formatAxiosErrorMessage(error))));
};

export const DeleteEFormApi = (id) => async (dispatch) => {
    dispatch(removeEFormRequesting());
    apiService
        .delete(`${endPoints.e_form.create}${id}/`, {})
        .then((response) => {
            dispatch(removeEFormSuccess());
        })
        .catch((error) => {
            dispatch(removeEFormError(formatAxiosErrorMessage(error)));
        });
};
export const ResponseRetrieveEFormApi = (id) => async (dispatch) => {
    dispatch(retrieveEFormRequesting());
    apiService
        .get(`${endPoints.e_form.response}${id}/`, {})
        .then((response) => {
            dispatch(retrieveEFormSuccess(response.data));
        })
        .catch((error) => dispatch(retrieveEFormError(formatAxiosErrorMessage(error))));
};

export const ResponseListEFormApi = (query) => async (dispatch) => {
    dispatch(responseEFormRequesting());
    let stringQuery = qargsToQstring(query);
    let responseURL = `${endPoints.e_form.response}${stringQuery}`;
    apiService
        .get(responseURL, {})
        .then((response) => {
            dispatch(responseEFormSuccess(response.data));
        })
        .catch((error) => dispatch(responseEFormError(formatAxiosErrorMessage(error))));
};

export const EFormEventApi = (data) => async (dispatch) => {
    apiService.post(`${endPoints.e_form.event}`, data);
};

export const EFormGetEventApi = (query) => async (dispatch) => {
    dispatch(getEventsRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.e_form.event}${stringQuery}`, {})
        .then((response) => {
            dispatch(getEventsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(getEventsError(formatAxiosErrorMessage(error)));
        });
};

export const RetrieveFillingFormEFormApi = (id, viewData) => async (dispatch) => {
    dispatch(retrieveEFormRequesting());
    apiService
        .get(`${endPoints.e_form.clientInvite}${id}/`, {})
        .then((response) => {
            dispatch(retrieveEFormSuccess(response.data));
            dispatch(EFormEventApi(viewData));
        })
        .catch((error) => {
            dispatch(retrieveEFormError(formatAxiosErrorMessage(error)));
        });
};

export const GenerateResponseOTP = (id) => async (dispatch) => {
    dispatch(responseOtpRequesting());
    apiService
        .post(`${endPoints.e_form.generate_otp}${id}/`, {})
        .then((response) => {
            dispatch(responseOtpSuccess(response.data));
        })
        .catch((error) => {
            dispatch(responseOtpError(formatAxiosErrorMessage(error)));
        });
};

export const FilledFormEFormApi = (data) => async (dispatch) => {
    dispatch(saveEFormRequesting());
    apiService
        .post(endPoints.e_form.response, data)
        .then((response) => {
            dispatch(saveEFormSuccess());
        })
        .catch((error) => {
            dispatch(saveEFormError(formatAxiosErrorMessage(error)));
        });
};

export const ClientInviteEFormApi = (data) => async (dispatch) => {
    dispatch(clientInviteEFormRequesting());
    apiService
        .post(endPoints.e_form.clientInvite, data)
        .then((response) => {
            dispatch(clientInviteEFormSuccess(response.data));
        })
        .catch((error) => {
            dispatch(clientInviteEFormError(formatAxiosErrorMessage(error)));
        });
};

export const GetEFormAssignedUser = (id, query) => async (dispatch) => {
    dispatch(getAssignedRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.e_form.access_list}${id}/${stringQuery}`, {})
        .then((response) => {
            dispatch(getAssignedSuccess(response.data));
        })
        .catch((error) => dispatch(getAssignedError(formatAxiosErrorMessage(error))));
};

export const GetEFormUnassignedUser = () => async (dispatch) => {
    dispatch(getUnassignedRequesting());
    apiService
        .get(endPoints.e_form.unAssigned, {})
        .then((response) => {
            dispatch(getUnassignedSuccess(response.data));
        })
        .catch((error) => dispatch(getUnassignedError(formatAxiosErrorMessage(error))));
};

export const AssignEFormUser = (data) => async (dispatch) => {
    dispatch(assignRequesting());
    apiService
        .post(endPoints.e_form.access, data)
        .then((response) => {
            dispatch(assignSuccess(response.data));
        })
        .catch((error) => {
            dispatch(assignError(formatAxiosErrorMessage(error)));
        });
};

export const unAssignEFormUser = (data) => async (dispatch) => {
    dispatch(unAssignRequesting());
    apiService
        .post(endPoints.e_form.revoke, data)
        .then((response) => {
            dispatch(unAssignSuccess(response.data));
        })
        .catch((error) => {
            dispatch(unAssignError(formatAxiosErrorMessage(error)));
        });
};

export const downloadEFormResponse = (id) => async (dispatch) => {
    dispatch(downloadRequesting());

    apiService
        .get(`${endPoints.e_form.download}${id}/`, {})
        .then((response) => {
            dispatch(downloadSuccess(response.data));
        })
        .catch((error) => {
            dispatch(downloadError(formatAxiosErrorMessage(error)));
        });
};
export const mailEFormResponse = (id) => async (dispatch) => {
    dispatch(mailEformResponseRequesting());
    apiService
        .post(`${endPoints.e_form.mail}${id}/`, {})
        .then((response) => {
            dispatch(mailEformResponseSuccess(response.data));
        })
        .catch((error) => {
            dispatch(mailEformResponseError(formatAxiosErrorMessage(error)));
        });
};


export const updateResponseAPI = (id, payload) => async (dispatch) => {
    dispatch(updateResponseRequesting());
    apiService
        .patch(`${endPoints.e_form.response}${id}/`, payload)
        .then((response) => {
            dispatch(updateResponseSuccess(response.data));
        })
        .catch((error) => {
            dispatch(updateResponseError(formatAxiosErrorMessage(error)));
        });
};

export const PdfGenerationStatusAPI = (payload) => async (dispatch) => {
    dispatch(pdfGenerationStatusRequesting());
    apiService
        .post(`${endPoints.e_form.pdfGenerationStatus}`, payload)
        .then((response) => {
            dispatch(pdfGenerationStatusSuccess(response.data));
        })
        .catch((error) => {
            dispatch(pdfGenerationStatusError(formatAxiosErrorMessage(error)));
        });
};

export const {
    createEFormRequesting, createEFormSuccess, createEFormError, createEFormReset,

    getEFormRequesting, getEFormSuccess, getEFormError, getEFormReset,

    retrieveEFormRequesting, retrieveEFormSuccess, retrieveEFormError, retrieveEFormReset,

    saveEFormRequesting, saveEFormSuccess, saveEFormError, saveEFormReset,

    removeEFormRequesting, removeEFormSuccess, removeEFormError, removeEFormReset,

    clientInviteEFormRequesting, clientInviteEFormSuccess, clientInviteEFormError, clientInviteEFormReset,

    responseEFormRequesting, responseEFormSuccess, responseEFormError, responseEFormReset,

    getUnassignedRequesting, getUnassignedSuccess, getUnassignedError, getUnassignedReset,

    getAssignedRequesting, getAssignedSuccess, getAssignedError, getAssignedReset,

    assignRequesting, assignSuccess, assignError, assignReset,

    unAssignRequesting, unAssignSuccess, unAssignError, unAssignReset,

    downloadRequesting, downloadSuccess, downloadError, downloadReset,

    getEventsRequesting, getEventsSuccess, getEventsError, getEventsReset,

    mailEformResponseRequesting, mailEformResponseSuccess, mailEformResponseError, mailEformResponseReset,

    updateResponseRequesting, updateResponseSuccess, updateResponseError, updateResponseReset,


    responseOtpRequesting, responseOtpSuccess, responseOtpError, responseOtpReset,


    pdfGenerationStatusRequesting, pdfGenerationStatusSuccess, pdfGenerationStatusError, pdfGenerationStatusReset,

    responseEFormUpdate,

    autoSaveEFormRequesting,
    autoSaveEFormSuccess,
    autoSaveEFormReset,
    autoSaveEFormError,

    EformMasterReset,
} = EFormSlice.actions;
export const EFormState = (state) => state.e_form;
export default EFormSlice.reducer;
