/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/13/2022
 * Time: 9:54 AM
 * Project: w_crm
 */

export const URL_HOME = "/";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_PROFILE = "/profile";
export const URL_SUBSCRIPTION = "/subscription";
export const URL_CALENDAR = "/calendar";
export const URL_PRICING = "/pricing";
export const URL_ORGANIZATION = "/organization";
export const URL_INTEGRATION = "/integration";
export const URL_WHATSAPP_INTEGRATION = "/whatsapp";
export const URL_DOCUMENTS = "/documents";
export const URL_TASK = "/task";
export const URL_ANALYTICS = "/analytics";
export const URL_CLIENTS = "/contact";
export const URL_Leads = "/leads";
export const URL_Whatsapp_Enquiry = "/whatsapp-enquiry";
export const URL_New_WHATSAPP_TEMPLATE = "/whatsapp-template-create";
export const URL_Appointment = "/appointment";
export const URL_CONTACT_WIDGET = "/contact-widget";
export const URL_CLIENT_DETAILS = URL_CLIENTS + "/:id";
export const URL_LEAD_DETAILS = URL_Leads + "/:id";
export const URL_APPOINTMENT_RESCHEDULE = URL_Appointment + "/:id/reschedule";
export const URL_E_FORM = "/e-form";
export const URL_CHATBOT = "/chatbot";
export const URL_EDIT_CHATBOT = "/edit-chatbot/:id";
export const URL_CREATE_E_FORM = URL_E_FORM + "/create";
export const URL_VIEW_E_FORM = URL_E_FORM + "/view";
export const URL_EDIT_E_FORM = URL_E_FORM + "/edit";
export const URL_FILL_E_FORM = URL_E_FORM + "/fill";
export const URL_RESPONSE_E_FORM = URL_E_FORM + "/response";

export const URL_LOGIN = "/login";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_REGISTER = "/register";
export const URL_RESET_PASSWORD = "/reset-password/:level1/:level2";
export const URL_VERIFY_EMAIL = "/verify-email/:level1/";
export const URL_NOTICE = "/notice";

export const URL_BOOKING = "/book/:level1";
export const URL_CONTACT_WILD = "/lead/*";
export const URL_CONTACT_T1 = "/lead/:level1";
export const URL_CONTACT_T2 = "/lead/:level1/:level2";
export const URL_CONTACT_T3 = "/lead/:level1/:level2/:level3";
export const URL_PAGE_NOT_FOUND = "/page-not-found";
export const URL_DEV_TESTING = "/testing"; // TODO remove in production
