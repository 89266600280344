import React from "react";
import UrlRoutes from "./routes/UrlRoutes";
import store from "./redux/store";
import {Provider} from "react-redux";
import {Slide, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {addCssAttribute} from "./utils/util";
import 'react-tooltip/dist/react-tooltip.css'
import {MaintenancePage} from "./pages/MaintenancePage/MaintenancePage";
import {IS_APP_MAINTENANCE} from "./utils/constants";

function App() {
    addCssAttribute("--primary-dark", process.env.REACT_APP_PRIMARY_DARK_COLOR);
    addCssAttribute("--primary", process.env.REACT_APP_PRIMARY_COLOR);
    const version = process.env.REACT_APP_DEV_VERSION
    return (
        <>{IS_APP_MAINTENANCE === 'false' ?
            <>
                <Provider store={store}>
                    <UrlRoutes/>
                </Provider>
                <ToastContainer transition={Slide}/></> :
            <MaintenancePage/>
        }
            {process.env.REACT_APP_ENV === "development" && version &&
                <div style={{
                    position: "absolute",
                    zIndex: 1000,
                    right: 0,
                    bottom: 0,
                    padding: 5,
                    background: "red",
                    color: "white",
                    fontStyle: "bold"
                }}>
                    Development version : {version}
                </div>
            }
        </>
    );
}

export default App;
