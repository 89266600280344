import {useLocation} from "react-router-dom";
import React, {useRef} from "react";
import {qargsToQstring} from "./common";
import {getLocalStorage} from "./localStorage";
import {COUNTRY_CODE} from "./constants";

const moment = require("moment-timezone");

/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/3/2022
 * Time: 5:33 PM
 * Project: w_crm
 */

export const limitText = (text, count) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
};

export const sortObj = (list, key) => {
    function compare(a, b) {
        a = a[key];
        b = b[key];
        const type =
            typeof a === "string" || typeof b === "string" ? "string" : "number";
        let result;
        if (type === "string") result = a.localeCompare(b);
        else result = a - b;
        return result;
    }

    return list.sort(compare);
};

export const getPercentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
};

export const reversePercentage = (percentage, totalValue) => {
    return (percentage * totalValue) / 100;
};

export const getUUID = () => {
    let u = "",
        m = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
        i = 0,
        rb = (Math.random() * 0xffffffff) | 0;
    while (i++ < 36) {
        const c = m[i - 1],
            r = rb & 0xf,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        u += c === "-" || c === "4" ? c : v.toString(16);
        rb = i % 8 === 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 4;
    }
    return u;
};


export const getLabel = (key) => {
    const randomNum = Math.floor(100 + Math.random() * 900); // Generates a 3-digit random number
    return `${key}-${randomNum}`;
};



Array.prototype.containsAll = function () {
    return Array.from(arguments).every(i => this.includes(i));
}

export const useQuery = () => {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};
export const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const isTimeFuture = (timeDate) => {
    let raw = new Date(timeDate).getTime();
    let today = new Date().getTime();
    return raw > today;
};
export const defaultTime = (timeDate, split = false, isApi = false) => {
    let raw = new Date(timeDate);
    let hour = raw.getHours();
    let minute = raw.getMinutes();
    let dayNight = "AM";
    if (hour > 12) {
        hour = hour - 12;
    }
    if (hour >= 12) {
        dayNight = "PM";
    }
    if (hour.toString().length === 1) {
        hour = `0${hour}`;
    }
    if (minute.toString().length === 1) {
        minute = `0${minute}`;
    }
    if (!isApi) {
        if (split) {
            return [
                `${raw.getDate()}-${monthList[raw.getMonth()]}-${raw.getFullYear()}`,
                `${hour}:${minute} ${dayNight}`,
            ];
        } else {
            return `${
                monthList[raw.getMonth()]
            } ${raw.getDate()} ${raw.getFullYear()} ${hour}:${minute} ${dayNight}`;
        }
    } else {
        return `${raw.getMonth() + 1} ${raw.getDate()} ${raw.getFullYear()}`;
    }
};
export const toUTC = (timeDate) => {
    let raw = new Date(timeDate).toUTCString().split(" ");
    let time = raw[4].split(":");
    return `${raw[3]}-${monthList.indexOf(raw[2]) + 1}-${raw[1]}T${time[0]}:${
        time[1]
    }`;
};

export const removeArrayItem = (arrOriginal, elementToRemove) => {
    return arrOriginal.filter(function (el) {
        return el !== elementToRemove;
    });
};

export const removeArrayObjectItem = (arrOriginal, id) => {
    return arrOriginal.filter(function (el) {
        return el.id !== id;
    });
};

export const removeDuplicates = (arr) => {
    const uniqueIds = {}; // Object to store unique IDs
    return arr.filter((item) => {
        if (!uniqueIds[item.id]) {
            uniqueIds[item.id] = true; // Mark the ID as seen
            return true; // Keep the item in the filtered array
        }
        return false; // Ignore the item (duplicate)
    });
};

export const replaceArrayObjectItem = (arrOriginal = [], newObject) => {
    let temp = [...arrOriginal];
    const objIndex = temp.findIndex((obj) => obj.id === newObject.id);
    temp[objIndex] = newObject;
    return removeDuplicates(temp);
};

export const getDelimiter = (val) => {
    if (val === "Tab") {
        return "  ";
    } else {
        return val;
    }
};

export const convert12To24 = (_time) => {
    let splitter = _time.split(" ");
    let numericTime = splitter[0].split(":");
    let finalHour = parseInt(numericTime[0]);

    if (splitter[1] === "PM" && finalHour !== 12) {
        finalHour = finalHour + 12;
    }

    return {
        hour: finalHour,
        minute: parseInt(numericTime[1]),
    };
};

export const stringToDate = (_date, _format, _delimiter) => {
    const formatLowerCase = _format.toLowerCase();
    const formatItems = formatLowerCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf("mm");
    const dayIndex = formatItems.indexOf("dd");
    const yearIndex = formatItems.indexOf("yyyy");
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;
    return new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
};
export const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
};
export const roundNearFive = (min) => {
    const reminder = min % 5;
    let final;
    if (reminder === 0) {
        final = min;
    } else {
        if (2.5 > reminder) {
            final = min - reminder;
        } else {
            final = min + (5 - reminder);
        }
    }
    if (final >= 15) {
        return final;
    } else {
        return 15;
    }
};

export const changeTimezone = (date, ianatz) => {
    const invdate = new Date(
        date.toLocaleString("en-US", {
            timeZone: ianatz,
        })
    );
    const diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
};

export const toTimeZone = (time, zone) => {
    const format = "YYYY/MM/DD HH:mm:ss ZZ";
    // console.log(zone);
    return moment(time, format).tz(zone).format(format);
};

export const wait = (ms) => {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
        end = new Date().getTime();
    }
};
export const changeTimeFormate = (date, format, inputFormat = null) => {
    if (inputFormat) {
        return moment(date, inputFormat).format(format);
    } else {
        return moment(date).format(format);
    }
};
export const changeToTimeDateLocalFormate = (date) => {
    return new Date(
        new Date(date).getTime() + new Date().getTimezoneOffset() * -60 * 1000
    )
        .toISOString()
        .slice(0, 19);
};
export const AppointMentStringDate = (start, end, split = false) => {
    const start_date = new Date(start);
    const end_date = new Date(end);
    if (start_date.toLocaleDateString() === end_date.toLocaleDateString()) {
        if (!split) {
            return `${changeTimeFormate(start_date, "MMM DD YYYY")} 
      at 
      ${changeTimeFormate(start_date, "hh:mm A")} - ${changeTimeFormate(
                end_date,
                "hh:mm A"
            )}`;
        } else {
            return [
                changeTimeFormate(start_date, "MMM DD YYYY"),
                changeTimeFormate(start_date, "hh:mm A"),
                changeTimeFormate(end_date, "hh:mm A"),
            ];
        }
    } else {
        if (!split) {
            return `${changeTimeFormate(
                start_date,
                "MMM DD YYYY"
            )} at ${changeTimeFormate(start_date, "hh:mm A")} - 
    ${changeTimeFormate(end_date, "MMM DD YYYY")} at ${changeTimeFormate(
                end_date,
                "hh:mm A"
            )}
    `;
        } else {
            return [
                changeTimeFormate(start_date, "MMM DD YYYY"),
                changeTimeFormate(start_date, "hh:mm A"),
                changeTimeFormate(end_date, "MMM DD YYYY"),
                changeTimeFormate(end_date, "hh:mm A"),
            ];
        }
    }
};

export const getMinBetweenTwoTime = (start, end) => {
    var duration = moment.duration(moment(end).diff(start));
    return duration.asMinutes();
};

export const isTimeInFuture = (time) => {
    return moment() < moment(time);
};

export const removeHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};

export const groupBy = (list, keyGetter) => {
    const map = {};
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map[key];
        if (!collection) {
            map[key] = [item];
        } else {
            collection.push(item);
        }
    });
    return map;
};

export const encrypt = (text, key) => {
    return [...text]
        .map((x, i) =>
            (x.codePointAt() ^ key.charCodeAt(i % key.length) % 255)
                .toString(16)
                .padStart(2, "0")
        )
        .join("");
};
export const decrypt = (text, key) => {
    if (text) {
        return String.fromCharCode(
            ...text
                .match(/.{1,2}/g)
                .map((e, i) => parseInt(e, 16) ^ key.charCodeAt(i % key.length) % 255)
        );
    } else {
        return null;
    }
};

export const addCssAttribute = (name, value) => {
    document.documentElement.style.setProperty(name, value);
};

export const getCalendarItems = (raw, date, cancelled) => {
    let result_date = [];
    raw.forEach((item, idx) => {
        const current_event = new Date(item.time);
        if (date === `${current_event.getDate()}/${current_event.getMonth() + 1}/${current_event.getFullYear()}`) {
            if (item.item.cancelled) {
                if (cancelled) {
                    result_date.push(item);
                }
            } else {
                result_date.push(item);
            }
        }
    });

    return result_date;
};


// export const getCalendarGroupedItems = (raw, cancelled, max) => {
//     const groupedByDate = {};
//
//     raw.forEach((item) => {
//         const currentEvent = new Date(item.time);
//         const formattedDate = `${currentEvent.getDate()}/${currentEvent.getMonth() + 1}/${currentEvent.getFullYear()}`;
//
//         if (!groupedByDate[formattedDate]) {
//             groupedByDate[formattedDate] = [];
//         }
//
//         // Prepare the new item with an initial position
//         const newItem = {
//             ...item,
//             position: groupedByDate[formattedDate].length + 1,
//         };
//
//         if (item.item.cancelled) {
//             if (cancelled) {
//                 groupedByDate[formattedDate].push(newItem);
//             }
//         } else {
//             // Check if the item is extended from the previous day
//             if (item.extended && !item.start) {
//                 const previousDate = getPreviousDate(formattedDate);  // Assuming this function gives you the previous date string
//                 const previousItems = groupedByDate[previousDate] ?? [];
//
//                 // Find the position from the previous day's item and apply it here
//                 const matchingItemFromPrevious = previousItems.find(preItem => preItem.item.id === item.item.id); // Use a unique identifier like id
//                 if (matchingItemFromPrevious) {
//                     newItem.position = matchingItemFromPrevious.position;
//                 }
//             }
//
//             // Add the item to the group for the current date
//             groupedByDate[formattedDate].push(newItem);
//
//             // Now let's handle the case where the current tile has extended items, and we need to sequence the non-extended ones
//             const currentTileItems = groupedByDate[formattedDate];
//             const extendedItems = currentTileItems.filter(tileItem => tileItem.extended); // All extended items
//             const nonExtendedItems = currentTileItems.filter(tileItem => !tileItem.extended); // All non-extended items
//
//             // If there are extended items in the current tile, start assigning positions to non-extended items after the last extended item
//             if (extendedItems.length > 0) {
//                 const lastExtendedItemPosition = extendedItems[extendedItems.length - 1].position;
//                 let nextPosition = lastExtendedItemPosition + 1;  // Start from the next position after the last extended item
//
//                 // Check if the first item is extended and has position max (e.g., 4) and if so, reset it to 1
//                 if (extendedItems[0].position === max && extendedItems[0].extended) {
//                     extendedItems[0].position = 1;
//                     extendedItems[0].restart = true;
//                     nextPosition = 2;  // Start from position 2 for the next items
//                 }
//
//                 // Sequence all items in the current tile
//                 nonExtendedItems.forEach((tileItem) => {
//                     tileItem.position = nextPosition++;  // Increment position for each non-extended item
//                 });
//             } else {
//                 // If no extended items exist, simply assign positions to all non-extended items sequentially
//                 nonExtendedItems.forEach((tileItem, index) => {
//                     tileItem.position = index + 1;  // Assign positions starting from 1
//                 });
//             }
//         }
//     });
//
//     // Sort items in each date group by position after position adjustment
//     Object.keys(groupedByDate).forEach(date => {
//         groupedByDate[date].sort((a, b) => a.position - b.position);
//     });
//
//     return groupedByDate;
// };


export const getCalendarGroupedItems = (raw, cancelled, max) => {
    const groupedByDate = {};

    raw.forEach((item) => {
        const currentEvent = new Date(item.time);
        const formattedDate = `${currentEvent.getDate()}/${currentEvent.getMonth() + 1}/${currentEvent.getFullYear()}`;

        if (!groupedByDate[formattedDate]) {
            groupedByDate[formattedDate] = [];
        }

        const newItem = {
            ...item,
            position: groupedByDate[formattedDate].length + 1,
        };

        if (item.item.cancelled) {
            if (cancelled) {
                groupedByDate[formattedDate].push(newItem);
            }
        } else {
            if (item.extended && !item.start) {
                const previousDate = getPreviousDate(formattedDate);
                const previousItems = groupedByDate[previousDate] ?? [];

                const matchingItemFromPrevious = previousItems.find(
                    (preItem) => preItem.item.id === item.item.id
                );

                if (matchingItemFromPrevious) {
                    newItem.position = matchingItemFromPrevious.position;
                }
            }

            groupedByDate[formattedDate].push(newItem);

            const currentTileItems = groupedByDate[formattedDate];
            const extendedItems = currentTileItems.filter((tileItem) => tileItem.extended);
            const nonExtendedItems = currentTileItems.filter((tileItem) => !tileItem.extended);

            if (extendedItems.length > 0) {
                let lastExtendedItemPosition = extendedItems[extendedItems.length - 1].position;
                let nextPosition = lastExtendedItemPosition + 1;

                if (extendedItems[0].position === max && extendedItems[0].extended) {
                    extendedItems[0].position = 1;
                    extendedItems[0].restart = true;
                    nextPosition = 2;
                }

                nonExtendedItems.forEach((tileItem) => {
                    tileItem.position = nextPosition++;
                });
            } else {
                nonExtendedItems.forEach((tileItem, index) => {
                    tileItem.position = index + 1;
                });
            }
        }
    });

    Object.keys(groupedByDate).forEach((date) => {
        const items = groupedByDate[date];
        if (items.length > 0) {
            const firstPosition = items[0].position;

            if (firstPosition > 1) {
                const numberOfDummies = firstPosition - 1;
                const dummyObjects = Array.from({length: numberOfDummies}, (_, index) => ({
                    item: {id: `dummy-${index + 1}`, name: 'Placeholder', cancelled: false},
                    position: index + 1,
                    extended: false,
                    start: true,
                    isDummy: true,
                }));

                groupedByDate[date] = [...dummyObjects, ...items];
            }
        }

        groupedByDate[date].sort((a, b) => a.position - b.position);
    });

    return groupedByDate;
};


export const getNameAttribute = (parentElement) => {
    let myID = null;
    if (Array.from(parentElement.getElementsByClassName("row")).length === 0) {
        const childElements = parentElement.getElementsByTagName("*");
        for (let i = 0; i < childElements.length; i++) {
            let childElement = childElements[i];
            const name = childElement.getAttribute("name");
            if (name) {
                myID = name;
                break;
            }
        }
    }
    return myID;
};

export const findItemByFieldIdIndividual = (data, current) => {
    return data.onlyStaff.find((item) => current.field_name === item) || null;
};

export const findItemByOptionsKeyIndividual = (data, current) => {
    return (
        data.find((item) => {
            let searchKey = item;
            if (item.startsWith("option_")) {
                searchKey = item.substring("option_".length);
            }
            return (
                current.options &&
                current.options.some((option) => option.key === searchKey)
            );
        }) || null
    );
};

export const findItemByFieldId = (data, fieldId) => {
    return data.find((item) => item.field_name === fieldId) || null;
};

export const findItemByOptionsKey = (data, optionsKey) => {
    let searchKey = optionsKey;
    if (optionsKey.startsWith("option_")) {
        searchKey = optionsKey.substring("option_".length);
    }
    return (
        data.find(
            (item) =>
                item.options && item.options.some((option) => option.key === searchKey)
        ) || null
    );
};

export const areObjectsEqual = (a, b) => {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (const key of keysA) {
        if (a[key] !== b[key]) {
            return false;
        }
    }

    return true;
};

export const hasQueryParams = (url) => {
    return /\?/.test(url);
}
export const apiUrlBuilder = (url, urlData, queryData) => {
    let finalUrl = url
    for (const key in urlData) {
        finalUrl = finalUrl.replace(`{{${key}}}`, urlData[key]);
    }
    if (queryData) {
        finalUrl = finalUrl + qargsToQstring(queryData)
    }
    return finalUrl
}

export const matchUrlPattern = (url, pattern) => {
    const regex = new RegExp(pattern.replace(/\{\{.*?\}\}/g, '.*'));
    return regex.test(url);
}


export const validatePassword = (password) => {
    // Minimum length check
    if (password.length < 4) {
        return "Password must be at least 4 characters long.";
    }

    // Maximum length check
    if (password.length > 15) {
        return "Password must be at most 15 characters long.";
    }

    // Alphabetic character check
    if (!/[A-Za-z]/.test(password)) {
        return "Password must contain at least one alphabetic character.";
    }

    // Numeric character check
    if (!/\d/.test(password)) {
        return "Password must contain at least one numeric character.";
    }

    // Special character check
    if (!/[!@#$%^&*()_+]/.test(password)) {
        return "Password must contain at least one special character (!@#$%^&*()_+).";
    }

    // All conditions passed
    return true;
}

export const formatStartTimeInUTC = (time) => {
    const isValidDate = (dateString) => !isNaN(new Date(dateString).getTime());
    if (!isValidDate(time)) {
        throw new Error("Invalid date format");
    }
    return new Date(time).toISOString()
}

export const convertToServerTimeFormate = (date) => {
    let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    const timeZoneOffset = (offset < 0 ? "" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}


export const getPastDateRange = (dayCount = 30) => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - dayCount);

    const formatDate = (date) => date.toISOString().split('T')[0];

    return {
        start: formatDate(startDate),
        end: formatDate(today),
    };
}
export const getDateRangeFromCache = (key = "waxis", dayCount = 30) => {
    const cache = JSON.parse(getLocalStorage(key)) || {};
    const endDate = new Date(cache.end || Date.now());
    let startDate;

    if (cache.start && cache.end) {
        startDate = new Date(cache.start);
    } else {
        startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - dayCount);
    }
    const formatDate = (date) => date.toISOString().split('T')[0];
    return {
        start: formatDate(startDate),
        end: formatDate(endDate),
    };
};

export const getFilterOptionFromCache = (key = "waxis") => {
    const cache = JSON.parse(getLocalStorage(key));
    return cache ? cache : undefined;
};


export const getDomainFromUrl = (url) => {
    try {
        const urlObject = new URL(url);
        return urlObject.hostname.replace(".com", "").replace("www.", "");
    } catch (error) {
        return url;
    }
}

export const calculateGrowthPercentage = (currentCount, oldCount) => {
    let growth = currentCount >= oldCount;
    let percentage = ((currentCount - oldCount) / oldCount) * 100;
    if (oldCount === 0 && currentCount === 0) {
        percentage = 0
    } else if (oldCount === 0) {
        percentage = 100
    }
    return {
        growth: growth,
        percentage: parseFloat(percentage.toFixed(2))
    };
}


export const downloadFileFromUrl = async (url) => {
    try {
        const response = await fetch(url);
        const reader = response.body.getReader();

        // Initialize the array buffer to hold the file data
        const chunks = [];
        let done = false;
        while (!done) {
            const {value, done: readerDone} = await reader.read();
            done = readerDone;
            if (value) {
                chunks.push(value);
            }
        }

        // Concatenate the chunks into a single Uint8Array
        const fileData = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0));
        let offset = 0;
        for (const chunk of chunks) {
            fileData.set(chunk, offset);
            offset += chunk.length;
        }

        // Create a Blob from the file data
        const blob = new Blob([fileData], {type: 'application/octet-stream'});

        // Create a URL for the Blob object
        const blobUrl = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'filename'; // Specify the filename here
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

export const base64ToBlob = (base64data) => {
    const byteString = atob(base64data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: 'image/png'});
}
export const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
}
export const getMonthDifference = (date) => {
    const currentDate = new Date();
    const inputDate = new Date(date);

    const currentMonth = currentDate.getMonth();
    const inputMonth = inputDate.getMonth();
    const monthDiff = inputMonth - currentMonth;

    // If the input date is in the current month
    if (currentDate.getFullYear() === inputDate.getFullYear() && currentMonth === inputMonth
    ) {
        return 0;
    }
// If the input date is in the next month
    else if (monthDiff === 1 || monthDiff === -11) { // -11 handles cases where input month is January and current month is December of the previous year
        return 1;
    }
// If the input date is in the previous month
    else if (monthDiff === -1 || monthDiff === 11) { // 11 handles cases where input month is December and current month is January of the next year
        return -1;
    } else {
        return monthDiff
    }
}

export const splitNumber = (value) => {
    const country_codes = COUNTRY_CODE;
    if (value) {
        const mobileNumber = `+${value.replace(/[^0-9]/g, '')}`;
        for (let i = 0; i < country_codes.length; i++) {
            if (mobileNumber.includes(country_codes[i])) {
                let code_size = country_codes[i].length;
                return {
                    "code": mobileNumber.substring(0, code_size).replace("+", ""),
                    "number": mobileNumber.substring(code_size)
                };
            }
        }
    }
}

export const splitMobileNumber = (value) => {
    const country_codes = COUNTRY_CODE;
    if (value) {
        const mobileNumber = `${value.replace(/[^0-9]/g, '')}`;
        for (let i = 0; i < country_codes.length; i++) {
            if (mobileNumber.includes(country_codes[i])) {
                let code_size = country_codes[i].length;
                return {
                    "code": mobileNumber.substring(0, code_size).replace("+", ""),
                    "number": mobileNumber.substring(code_size)
                };
            }
        }
    }
}

export const getMinDifferance = (start_time, end_time) => {
    try {
        const startDate = new Date(start_time);
        const endDate = new Date(end_time);
        const timeDifference = endDate - startDate;

        const differenceInMinutes = timeDifference / (1000 * 60);

        if (isNaN(differenceInMinutes)) {
            throw new Error("Invalid date difference calculation");
        }

        return differenceInMinutes;
    } catch (error) {
        console.error("Error calculating time difference:", error);
        return 15;
    }
}

export function getPreviousDate(currentDateStr) {
    const currentDate = moment(currentDateStr, 'D/M/YYYY');
    const previousDate = currentDate.subtract(1, 'days');
    return previousDate.format('D/M/YYYY');
}


export const CURRENCY_SYMBOL = {
    AED: 'د.إ',
    AFN: '؋',
    ALL: 'L',
    AMD: '֏',
    ANG: 'ƒ',
    AOA: 'Kz',
    ARS: '$',
    AUD: '$',
    AWG: 'ƒ',
    AZN: '₼',
    BAM: 'KM',
    BBD: '$',
    BDT: '৳',
    BGN: 'лв',
    BHD: '.د.ب',
    BIF: 'FBu',
    BMD: '$',
    BND: '$',
    BOB: '$b',
    BOV: 'BOV',
    BRL: 'R$',
    BSD: '$',
    BTC: '₿',
    BTN: 'Nu.',
    BWP: 'P',
    BYN: 'Br',
    BYR: 'Br',
    BZD: 'BZ$',
    CAD: '$',
    CDF: 'FC',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: '$',
    CNH: '¥',
    CNY: '¥',
    COP: '$',
    COU: 'COU',
    CRC: '₡',
    CUC: '$',
    CUP: '₱',
    CVE: '$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: 'RD$',
    DZD: 'دج',
    EEK: 'kr',
    EGP: '£',
    ERN: 'Nfk',
    ETB: 'Br',
    ETH: 'Ξ',
    EUR: '€',
    FJD: '$',
    FKP: '£',
    GBP: '£',
    GEL: '₾',
    GGP: '£',
    GHC: '₵',
    GHS: 'GH₵',
    GIP: '£',
    GMD: 'D',
    GNF: 'FG',
    GTQ: 'Q',
    GYD: '$',
    HKD: '$',
    HNL: 'L',
    HRK: 'kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    IMP: '£',
    INR: '₹',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'kr',
    JEP: '£',
    JMD: 'J$',
    JOD: 'JD',
    JPY: '¥',
    KES: 'KSh',
    KGS: 'лв',
    KHR: '៛',
    KMF: 'CF',
    KPW: '₩',
    KRW: '₩',
    KWD: 'KD',
    KYD: '$',
    KZT: '₸',
    LAK: '₭',
    LBP: '£',
    LKR: '₨',
    LRD: '$',
    LSL: 'M',
    LTC: 'Ł',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'LD',
    MAD: 'MAD',
    MDL: 'lei',
    MGA: 'Ar',
    MKD: 'ден',
    MMK: 'K',
    MNT: '₮',
    MOP: 'MOP$',
    MRO: 'UM',
    MRU: 'UM',
    MUR: '₨',
    MVR: 'Rf',
    MWK: 'MK',
    MXN: '$',
    MXV: 'MXV',
    MYR: 'RM',
    MZN: 'MT',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: '₨',
    NZD: '$',
    OMR: '﷼',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: 'Gs',
    QAR: '﷼',
    RMB: '￥',
    RON: 'lei',
    RSD: 'Дин.',
    RUB: '₽',
    RWF: 'R₣',
    SAR: '﷼',
    SBD: '$',
    SCR: '₨',
    SDG: 'ج.س.',
    SEK: 'kr',
    SGD: 'S$',
    SHP: '£',
    SLL: 'Le',
    SOS: 'S',
    SRD: '$',
    SSP: '£',
    STD: 'Db',
    STN: 'Db',
    SVC: '$',
    SYP: '£',
    SZL: 'E',
    THB: '฿',
    TJS: 'SM',
    TMT: 'T',
    TND: 'د.ت',
    TOP: 'T$',
    TRL: '₤',
    TRY: '₺',
    TTD: 'TT$',
    TVD: '$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    USD: '$',
    UYI: 'UYI',
    UYU: '$U',
    UYW: 'UYW',
    UZS: 'лв',
    VEF: 'Bs',
    VES: 'Bs.S',
    VND: '₫',
    VUV: 'VT',
    WST: 'WS$',
    XAF: 'FCFA',
    XBT: 'Ƀ',
    XCD: '$',
    XOF: 'CFA',
    XPF: '₣',
    XSU: 'Sucre',
    XUA: 'XUA',
    YER: '﷼',
    ZAR: 'R',
    ZMW: 'ZK',
    ZWD: 'Z$',
    ZWL: '$'
}

export const formatFlowKeys = (key) => {
    return key?.replace(/(^|_)([a-z])/g, (_, space, letter) => (space ? " " : "") + letter.toUpperCase())?.replaceAll("Preview", "");
}