/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 19/02/24
 * Time: 4:29 pm
 * Project: W-CRM
 */
import {createSlice, current} from "@reduxjs/toolkit"
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage} from "../../utils/common";
import {endPoints} from "../../api/endpoints";
import {apiUrlBuilder, removeDuplicates} from "../../utils/util";


const WhatsappIntegrationInitialState = {
    listMessages: {
        isLoading: false,
        data: null,
        error: null,
    },
    searchMessage: {
        data: null
    },
    sendMessage: {
        isLoading: false,
        data: null,
        error: null,
    },
    templates: {
        isLoading: false,
        data: null,
        error: null,
    },
    integrated: {
        isLoading: false,
        data: null,
        error: null,
    },
    configuration: {
        isLoading: false,
        data: null,
        error: null,
    },
    createConfiguration: {
        isLoading: false,
        data: null,
        error: null,
    },
    deleteConfiguration: {
        isLoading: false,
        data: null,
        error: null,
    },
    updateConfiguration: {
        isLoading: false,
        data: null,
        error: null,
    },
    analytics: {
        isLoading: false,
        data: null,
        error: null,
    },
    bulk: {
        isLoading: false,
        data: null,
        error: null,
    },
    unidentified_sender: {
        isLoading: false,
        data: null,
        error: null,
    },
    unidentified_email_sender: {
        isLoading: false,
        data: null,
        error: null,
    },
    delete_unidentified_sender: {
        isLoading: false,
        data: null,
        error: null,
    },
    create_template: {
        isLoading: false,
        data: null,
        error: null,
    },
    delete_template: {
        isLoading: false,
        data: null,
        error: null,
    },
    bulk_status: {
        isLoading: false,
        data: null,
        error: null,
    },
    create_chatbot: {
        isLoading: false,
        data: null,
        error: null,
    },
    list_chatbot: {
        isLoading: false,
        data: null,
        error: null,
    },
    delete_chatbot: {
        isLoading: false,
        data: null,
        error: null,
    },
    fetch_chatbot: {
        isLoading: false,
        data: null,
        error: null,
    },
    update_chatbot: {
        isLoading: false,
        data: null,
        error: null,
    }
}

export const WhatsappSlice = createSlice({
    name: "whatsappintegration",
    initialState: {
        ...WhatsappIntegrationInitialState
    }, reducers: {
        ListWhatsappMessageRequesting: (state, action) => {
            state.listMessages.isLoading = true
        },
        ListWhatsappMessageSuccess: (state, action) => {
            const {listMessages} = current(state);
            const newData = action.payload.data;

            if (listMessages?.data?.results) {
                let processed = removeDuplicates([
                    ...listMessages.data.results,
                    ...newData.results,
                ]);
                const sortedList = processed.sort((a, b) => {
                    return new Date(b.created_on) - new Date(a.created_on);
                });
                newData.results = sortedList
            }

            state.listMessages.isLoading = false
            state.listMessages.error = null
            // state.listMessages.data = action.payload.data
            state.listMessages.data = newData;
        },


        WhatsappMessageIncoming: (state, action) => {
            if (action.payload.data) {
                const {listMessages} = current(state);

                if (listMessages.data.results) {
                    const newMessage = action.payload.data;
                    let updatedResults = [...listMessages.data.results];
                    const existingIndex = updatedResults.findIndex(message => message.id === newMessage.id);
                    if (existingIndex !== -1) {
                        updatedResults[existingIndex] = newMessage;
                    } else {
                        updatedResults = removeDuplicates([...updatedResults, newMessage]);
                    }
                    const sortedList = updatedResults.sort((a, b) => {
                        return new Date(b.created_on) - new Date(a.created_on);
                    });
                    const newListMessages = {
                        ...listMessages,
                        data: {
                            ...listMessages.data,
                            results: sortedList
                        }
                    };
                    return {
                        ...state,
                        listMessages: newListMessages
                    };
                }
            }

        },


        SearchWhatsappMessageSuccess: (state, action) => {
            state.searchMessage.data = action.payload.data
            state.listMessages.isLoading = false
            state.listMessages.error = null

            const {listMessages} = current(state);

            const newData = action.payload.data;

            if (listMessages?.data?.results) {
                newData.results = removeDuplicates([...listMessages.data.results, ...newData.results,]);
                newData.count = listMessages.data.count
            }
            state.listMessages.data = newData;
        },

        ListWhatsappMessageError: (state, action) => {
            state.listMessages.isLoading = false
            state.listMessages.error = action.payload
        },
        ListWhatsappMessageReset: (state, action) => {
            state.listMessages = WhatsappIntegrationInitialState.listMessages
        },


        SearchWhatsappMessageReset: (state, action) => {
            state.searchMessage = WhatsappIntegrationInitialState.searchMessage
        },

        SendWhatsappMessageRequesting: (state, action) => {
            state.sendMessage.isLoading = true
        },
        SendWhatsappMessageSuccess: (state, action) => {
            state.sendMessage.isLoading = false
            state.sendMessage.error = null
            state.sendMessage.data = action.payload.data
        },
        SendWhatsappMessageError: (state, action) => {
            state.sendMessage.isLoading = false
            state.sendMessage.error = action.payload
        },
        SendWhatsappMessageReset: (state, action) => {
            state.sendMessage = WhatsappIntegrationInitialState.sendMessage
        },

        WhatsappTemplatesRequesting: (state, action) => {
            state.templates.isLoading = true
        },
        WhatsappTemplatesSuccess: (state, action) => {
            state.templates.isLoading = false
            state.templates.error = null
            state.templates.data = action.payload.data
        },
        WhatsappTemplatesError: (state, action) => {
            state.templates.isLoading = false
            state.templates.error = action.payload
        },
        WhatsappTemplatesReset: (state, action) => {
            state.templates = WhatsappIntegrationInitialState.templates
        },


        WhatsappIntegratedRequesting: (state, action) => {
            state.integrated.isLoading = true
        },
        WhatsappIntegratedSuccess: (state, action) => {
            state.integrated.isLoading = false
            state.integrated.error = null
            state.integrated.data = action.payload.data
        },
        WhatsappIntegratedError: (state, action) => {
            state.integrated.isLoading = false
            state.integrated.error = action.payload
        },
        WhatsappIntegratedReset: (state, action) => {
            state.integrated = WhatsappIntegrationInitialState.integrated
        },

        WhatsappConfigurationRequesting: (state, action) => {
            state.configuration.isLoading = true
        },
        WhatsappConfigurationSuccess: (state, action) => {
            state.configuration.isLoading = false
            state.configuration.error = null
            state.configuration.data = action.payload.data
        },
        WhatsappConfigurationError: (state, action) => {
            state.configuration.isLoading = false
            state.configuration.error = action.payload
        },
        WhatsappConfigurationReset: (state, action) => {
            state.configuration = WhatsappIntegrationInitialState.configuration
        },

        WhatsappCreateConfigurationRequesting: (state, action) => {
            state.createConfiguration.isLoading = true
        },
        WhatsappCreateConfigurationSuccess: (state, action) => {
            state.createConfiguration.isLoading = false
            state.createConfiguration.error = null
            state.createConfiguration.data = action.payload.data
        },
        WhatsappCreateConfigurationError: (state, action) => {
            state.createConfiguration.isLoading = false
            state.createConfiguration.error = action.payload
        },
        WhatsappCreateConfigurationReset: (state, action) => {
            state.createConfiguration = WhatsappIntegrationInitialState.createConfiguration
        },

        WhatsappDeleteConfigurationRequesting: (state, action) => {
            state.deleteConfiguration.isLoading = true
        },
        WhatsappDeleteConfigurationSuccess: (state, action) => {
            state.deleteConfiguration.isLoading = false
            state.deleteConfiguration.error = null
            state.deleteConfiguration.data = "deleted"
        },
        WhatsappDeleteConfigurationError: (state, action) => {
            state.deleteConfiguration.isLoading = false
            state.deleteConfiguration.data = null
            state.deleteConfiguration.error = action.payload
        },
        WhatsappDeleteConfigurationReset: (state, action) => {
            state.deleteConfiguration = WhatsappIntegrationInitialState.deleteConfiguration
        },

        WhatsappUpdateConfigurationRequesting: (state, action) => {
            state.updateConfiguration.isLoading = true
        },
        WhatsappUpdateConfigurationSuccess: (state, action) => {
            state.updateConfiguration.isLoading = false
            state.updateConfiguration.error = null
            state.updateConfiguration.data = action.payload.data
        },
        WhatsappUpdateConfigurationError: (state, action) => {
            state.updateConfiguration.isLoading = false
            state.updateConfiguration.error = action.payload
        },
        WhatsappUpdateConfigurationReset: (state, action) => {
            state.updateConfiguration = WhatsappIntegrationInitialState.updateConfiguration
        },


        WhatsappAnalyticsRequesting: (state, action) => {
            state.analytics.isLoading = true
        },
        WhatsappAnalyticsSuccess: (state, action) => {
            state.analytics.isLoading = false
            state.analytics.error = null
            state.analytics.data = action.payload.data
        },
        WhatsappAnalyticsError: (state, action) => {
            state.analytics.data = null
            state.analytics.isLoading = false
            state.analytics.error = action.payload
        },
        WhatsappAnalyticsReset: (state, action) => {
            state.analytics = WhatsappIntegrationInitialState.analytics
        },


        WhatsappBulkRequesting: (state, action) => {
            state.bulk.isLoading = true
        },
        WhatsappBulkSuccess: (state, action) => {
            state.bulk.isLoading = false
            state.bulk.error = null
            state.bulk.data = action.payload.data
        },
        WhatsappBulkError: (state, action) => {
            state.bulk.data = null
            state.bulk.isLoading = false
            state.bulk.error = action.payload
        },
        WhatsappBulkReset: (state, action) => {
            state.bulk = WhatsappIntegrationInitialState.bulk
        },


        WhatsappUnidentifiedSenderRequesting: (state, action) => {
            state.unidentified_sender.isLoading = true
        },
        WhatsappUnidentifiedSenderSuccess: (state, action) => {
            state.unidentified_sender.isLoading = false
            state.unidentified_sender.error = null
            state.unidentified_sender.data = action.payload.data
        },
        WhatsappUnidentifiedSenderError: (state, action) => {
            state.unidentified_sender.data = null
            state.unidentified_sender.isLoading = false
            state.unidentified_sender.error = action.payload
        },
        WhatsappUnidentifiedSenderReset: (state, action) => {
            state.unidentified_sender = WhatsappIntegrationInitialState.unidentified_sender
        },
        WhatsappUnidentifiedSenderMarkASRead: (state, action) => {
            const id = action.payload;
            if (id) {
                const {unidentified_sender} = current(state);
                const {data} = unidentified_sender;
                const updatedResults = data.results.map(item =>
                    item.id === id ? {...item, is_unread_messages: false} : item
                );
                state.unidentified_sender.data = {...data, results: updatedResults};
            }
        },


        EmailUnidentifiedSenderRequesting: (state, action) => {
            state.unidentified_email_sender.isLoading = true
        },
        EmailUnidentifiedSenderSuccess: (state, action) => {
            state.unidentified_email_sender.isLoading = false
            state.unidentified_email_sender.error = null
            state.unidentified_email_sender.data = action.payload.data
        },
        EmailUnidentifiedSenderError: (state, action) => {
            state.unidentified_email_sender.data = null
            state.unidentified_email_sender.isLoading = false
            state.unidentified_email_sender.error = action.payload
        },
        EmailUnidentifiedSenderReset: (state, action) => {
            state.unidentified_email_sender = WhatsappIntegrationInitialState.unidentified_email_sender
        },
        EmailUnidentifiedSenderMarkASRead: (state, action) => {
            const id = action.payload;
            if (id) {
                const {unidentified_sender} = current(state);
                const {data} = unidentified_sender;
                const updatedResults = data.results.map(item =>
                    item.id === id ? {...item, is_unread_messages: false} : item
                );
                state.unidentified_sender.data = {...data, results: updatedResults};
            }
        },


        DeleteWhatsappUnidentifiedSenderRequesting: (state, action) => {
            state.delete_unidentified_sender.isLoading = true
        },
        DeleteWhatsappUnidentifiedSenderSuccess: (state, action) => {
            state.delete_unidentified_sender.isLoading = false
            state.delete_unidentified_sender.error = null
            state.delete_unidentified_sender.data = "deleted"
        },
        DeleteWhatsappUnidentifiedSenderError: (state, action) => {
            state.delete_unidentified_sender.data = null
            state.delete_unidentified_sender.isLoading = false
            state.delete_unidentified_sender.error = action.payload
        },
        DeleteWhatsappUnidentifiedSenderReset: (state, action) => {
            state.delete_unidentified_sender = WhatsappIntegrationInitialState.delete_unidentified_sender
        },

        WhatsappCreateTemplateRequesting: (state, action) => {
            state.create_template.isLoading = true
        },
        WhatsappCreateTemplateSuccess: (state, action) => {
            state.create_template.isLoading = false
            state.create_template.error = null
            state.create_template.data = action.payload.data
        },
        WhatsappCreateTemplateError: (state, action) => {
            state.create_template.data = null
            state.create_template.isLoading = false
            state.create_template.error = action.payload
        },
        WhatsappCreateTemplateReset: (state, action) => {
            state.create_template = WhatsappIntegrationInitialState.create_template
        },

        WhatsappDeleteTemplateRequesting: (state, action) => {
            state.delete_template.isLoading = true
        },
        WhatsappDeleteTemplateSuccess: (state, action) => {
            state.delete_template.isLoading = false
            state.delete_template.error = null
            state.delete_template.data = "deleted"
        },
        WhatsappDeleteTemplateError: (state, action) => {
            state.delete_template.data = null
            state.delete_template.isLoading = false
            state.delete_template.error = action.payload
        },
        WhatsappDeleteTemplateReset: (state, action) => {
            state.delete_template = WhatsappIntegrationInitialState.delete_template
        },


        WhatsappBulkStatusRequesting: (state, action) => {
            state.bulk_status.isLoading = true
        },
        WhatsappBulkStatusSuccess: (state, action) => {
            state.bulk_status.isLoading = false
            state.bulk_status.error = null
            state.bulk_status.data = action.payload.data
        },
        WhatsappBulkStatusError: (state, action) => {
            state.bulk_status.data = null
            state.bulk_status.isLoading = false
            state.bulk_status.error = action.payload
        },
        WhatsappBulkStatusReset: (state, action) => {
            state.bulk_status = WhatsappIntegrationInitialState.bulk_status
        },


        CreateChatBotRequesting: (state, action) => {
            state.create_chatbot.isLoading = true
        },
        CreateChatBotSuccess: (state, action) => {
            state.create_chatbot.isLoading = false
            state.create_chatbot.error = null
            state.create_chatbot.data = action.payload.data
        },
        CreateChatBotError: (state, action) => {
            state.create_chatbot.data = null
            state.create_chatbot.isLoading = false
            state.create_chatbot.error = action.payload
        },
        CreateChatBotReset: (state, action) => {
            state.create_chatbot = WhatsappIntegrationInitialState.create_chatbot
        },

        ListChatBotRequesting: (state, action) => {
            state.list_chatbot.isLoading = true
        },
        ListChatBotSuccess: (state, action) => {
            state.list_chatbot.isLoading = false
            state.list_chatbot.error = null
            state.list_chatbot.data = action.payload.data
        },
        ListChatBotError: (state, action) => {
            state.list_chatbot.data = null
            state.list_chatbot.isLoading = false
            state.list_chatbot.error = action.payload
        },
        ListChatBotReset: (state, action) => {
            state.list_chatbot = WhatsappIntegrationInitialState.list_chatbot
        },

        DeleteChatBotRequesting: (state, action) => {
            state.delete_chatbot.isLoading = true
        },
        DeleteChatBotSuccess: (state, action) => {
            state.delete_chatbot.isLoading = false
            state.delete_chatbot.error = null
            state.delete_chatbot.data = "ok"
        },
        DeleteChatBotError: (state, action) => {
            state.delete_chatbot.data = null
            state.delete_chatbot.isLoading = false
            state.delete_chatbot.error = action.payload
        },
        DeleteChatBotReset: (state, action) => {
            state.delete_chatbot = WhatsappIntegrationInitialState.delete_chatbot
        },

        FetchChatBotRequesting: (state, action) => {
            state.fetch_chatbot.isLoading = true
        },
        FetchChatBotSuccess: (state, action) => {
            state.fetch_chatbot.isLoading = false
            state.fetch_chatbot.error = null
            state.fetch_chatbot.data = action.payload.data
        },
        FetchChatBotError: (state, action) => {
            state.fetch_chatbot.data = null
            state.fetch_chatbot.isLoading = false
            state.fetch_chatbot.error = action.payload
        },
        FetchChatBotReset: (state, action) => {
            state.fetch_chatbot = WhatsappIntegrationInitialState.fetch_chatbot
        },

        UpdateChatBotRequesting: (state, action) => {
            state.update_chatbot.isLoading = true
        },
        UpdateChatBotSuccess: (state, action) => {
            state.update_chatbot.isLoading = false
            state.update_chatbot.error = null
            state.update_chatbot.data = action.payload.data
        },
        UpdateChatBotError: (state, action) => {
            state.update_chatbot.data = null
            state.update_chatbot.isLoading = false
            state.update_chatbot.error = action.payload
        },
        UpdateChatBotReset: (state, action) => {
            state.update_chatbot = WhatsappIntegrationInitialState.update_chatbot
        },


        WhatsappResetState: () => WhatsappIntegrationInitialState
    }
})

export const listWhatsappMessageAPI = (query) => async dispatch => {
    dispatch(ListWhatsappMessageRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.listMessage, null, query), {})
        .then((response) => {
            dispatch(ListWhatsappMessageSuccess(response.data))
        })
        .catch((error) => dispatch(ListWhatsappMessageError(formatAxiosErrorMessage(error))));
}

export const listEnquiryWhatsappMessageAPI = (query) => async dispatch => {
    dispatch(ListWhatsappMessageRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.listEnquiryMessage, null, query), {})
        .then((response) => {
            dispatch(ListWhatsappMessageSuccess(response.data))
        })
        .catch((error) => dispatch(ListWhatsappMessageError(formatAxiosErrorMessage(error))));
}

export const searchWhatsappMessageAPI = (query) => async dispatch => {
    dispatch(ListWhatsappMessageRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.search, null, query), {})
        .then((response) => {
            // dispatch(ListWhatsappMessageSuccess(response.data))
            dispatch(SearchWhatsappMessageSuccess(response.data))
        })
        .catch((error) => dispatch(ListWhatsappMessageError(formatAxiosErrorMessage(error))));
}

export const listWhatsappTemplatesAPI = (query) => async dispatch => {
    dispatch(WhatsappTemplatesRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.templates, null, query), {})
        .then((response) => {
            dispatch(WhatsappTemplatesSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappTemplatesError(formatAxiosErrorMessage(error))));
}

export const createWhatsappTemplatesAPI = (data) => async dispatch => {
    dispatch(WhatsappCreateTemplateRequesting());
    apiService
        .post(apiUrlBuilder(endPoints.integration.templates, null, {}), data)
        .then((response) => {
            dispatch(WhatsappCreateTemplateSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappCreateTemplateError(formatAxiosErrorMessage(error))));
}

export const deleteWhatsappTemplatesAPI = (urlData) => async dispatch => {
    dispatch(WhatsappDeleteTemplateRequesting());
    apiService
        .delete(apiUrlBuilder(endPoints.integration.templates, {}, urlData), {})
        .then((response) => {
            dispatch(WhatsappDeleteTemplateSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappDeleteTemplateError(formatAxiosErrorMessage(error))));
}

export const sendWhatsappMessageAPI = (query, data) => async dispatch => {
    dispatch(SendWhatsappMessageRequesting());
    apiService
        .post(apiUrlBuilder(endPoints.integration.listMessage, null, query), data)
        .then((response) => {
            dispatch(SendWhatsappMessageSuccess(response.data))
            dispatch(WhatsappMessageIncoming(response.data))
        })
        .catch((error) => dispatch(SendWhatsappMessageError(formatAxiosErrorMessage(error))));
}

export const WhatsappIntegratedAPI = () => async dispatch => {
    dispatch(WhatsappIntegratedRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.isWhatsappConfig, null, {}), {})
        .then((response) => {
            dispatch(WhatsappIntegratedSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappIntegratedError(formatAxiosErrorMessage(error))));
}

export const WhatsappConfigurationAPI = () => async dispatch => {
    dispatch(WhatsappConfigurationRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.createConfig, null, {}), {})
        .then((response) => {
            dispatch(WhatsappConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappConfigurationError(formatAxiosErrorMessage(error))));
}

export const WhatsappAnalyticsAPI = (query) => async dispatch => {
    dispatch(WhatsappAnalyticsRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.whatsappAnalytics, null, query), {})
        .then((response) => {
            dispatch(WhatsappAnalyticsSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappAnalyticsError(formatAxiosErrorMessage(error))));
}

export const WhatsappUnidentifiedSenderAPI = (query) => async dispatch => {
    dispatch(WhatsappUnidentifiedSenderRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.unidentifiedSender, null, query), {})
        .then((response) => {
            dispatch(WhatsappUnidentifiedSenderSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappUnidentifiedSenderError(formatAxiosErrorMessage(error))));
}

export const EmailUnidentifiedSenderAPI = (query) => async dispatch => {
    dispatch(EmailUnidentifiedSenderRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.unidentifiedSender, null, query), {})
        .then((response) => {
            dispatch(EmailUnidentifiedSenderSuccess(response.data))
        })
        .catch((error) => dispatch(EmailUnidentifiedSenderError(formatAxiosErrorMessage(error))));
}

export const DeleteWhatsappUnidentifiedSenderAPI = (urlData, query) => async dispatch => {
    dispatch(DeleteWhatsappUnidentifiedSenderRequesting());
    apiService
        .delete(apiUrlBuilder(endPoints.integration.deleteUnidentifiedSender, urlData, query), {})
        .then((response) => {
            dispatch(DeleteWhatsappUnidentifiedSenderSuccess(response.data))
        })
        .catch((error) => dispatch(DeleteWhatsappUnidentifiedSenderError(formatAxiosErrorMessage(error))));
}

export const WhatsappCreateConfigurationAPI = (data) => async dispatch => {
    dispatch(WhatsappCreateConfigurationRequesting());
    apiService
        .post(apiUrlBuilder(endPoints.integration.createConfig, null, {}), data)
        .then((response) => {
            dispatch(WhatsappCreateConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappCreateConfigurationError(formatAxiosErrorMessage(error))));
}
export const WhatsappDeleteConfigurationAPI = (id, force = false) => async dispatch => {
    dispatch(WhatsappDeleteConfigurationRequesting());
    apiService
        .delete(apiUrlBuilder(endPoints.integration.createConfig + `${id}/`, null, {force: force}), {})
        .then((response) => {
            dispatch(WhatsappDeleteConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappDeleteConfigurationError(formatAxiosErrorMessage(error))));
}

export const WhatsappUpdateConfigurationAPI = (data) => async dispatch => {
    dispatch(WhatsappUpdateConfigurationRequesting());
    apiService
        .patch(apiUrlBuilder(endPoints.integration.whatsappPhoneNumberConfirmation, null, {}), data)
        .then((response) => {
            dispatch(WhatsappUpdateConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappUpdateConfigurationError(formatAxiosErrorMessage(error))));
}

export const WhatsappBulkMessageAPI = (data) => async dispatch => {
    dispatch(WhatsappBulkRequesting());
    apiService
        .post(apiUrlBuilder(endPoints.integration.bulkWhatsapp, null, {}), data)
        .then((response) => {
            dispatch(WhatsappBulkSuccess({data: "success bulk"}))
        })
        .catch((error) => dispatch(WhatsappBulkError(formatAxiosErrorMessage(error))));
}

export const WhatsappBulkStatusAPI = (data) => async dispatch => {
    dispatch(WhatsappBulkStatusRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.bulkWhatsappStatus, null, {}), data)
        .then((response) => {
            dispatch(WhatsappBulkStatusSuccess(response.data))
        })
        .catch((error) => dispatch(WhatsappBulkStatusError(formatAxiosErrorMessage(error))));
}


export const CreateChatBotAPI = (data) => async dispatch => {
    dispatch(CreateChatBotRequesting());
    apiService
        .post(apiUrlBuilder(endPoints.integration.chatbot.config, null, {}), data)
        .then((response) => {
            dispatch(CreateChatBotSuccess(response.data))
        })
        .catch((error) => dispatch(CreateChatBotError(formatAxiosErrorMessage(error))));
}

export const ListChatbotAPI = (data) => async dispatch => {
    dispatch(ListChatBotRequesting());
    // console.log(data)
    apiService
        .get(apiUrlBuilder(endPoints.integration.chatbot.config, null, data), data)
        .then((response) => {
            dispatch(ListChatBotSuccess(response.data))
        })
        .catch((error) => dispatch(ListChatBotError(formatAxiosErrorMessage(error))));
}

export const ChatbotDeleteAPI = (id) => async dispatch => {
    dispatch(DeleteChatBotRequesting());
    apiService
        .delete(apiUrlBuilder(endPoints.integration.chatbot.config + `${id}/`, null, {}), {})
        .then((response) => {
            dispatch(DeleteChatBotSuccess(response.data))
        })
        .catch((error) => dispatch(DeleteChatBotError(formatAxiosErrorMessage(error))));
}

export const FetchChatbotAPI = (id) => async dispatch => {
    dispatch(FetchChatBotRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.integration.chatbot.config + `${id}/`, null, {}), {})
        .then((response) => {
            dispatch(FetchChatBotSuccess(response.data))
        })
        .catch((error) => dispatch(FetchChatBotError(formatAxiosErrorMessage(error))));
}


export const UpdateChatBotAPI = (id, data) => async dispatch => {
    dispatch(UpdateChatBotRequesting());
    apiService
        .patch(endPoints.integration.chatbot.config + id + "/", data)
        .then((response) => {
            dispatch(UpdateChatBotSuccess(response.data))
        })
        .catch((error) => dispatch(UpdateChatBotError(formatAxiosErrorMessage(error))));
}

export const {
    ListWhatsappMessageRequesting,
    ListWhatsappMessageSuccess,
    ListWhatsappMessageReset,
    ListWhatsappMessageError,

    SendWhatsappMessageRequesting,
    SendWhatsappMessageSuccess,
    SendWhatsappMessageReset,
    SendWhatsappMessageError,

    WhatsappTemplatesRequesting,
    WhatsappTemplatesSuccess,
    WhatsappTemplatesError,
    WhatsappTemplatesReset,
    SearchWhatsappMessageSuccess,
    SearchWhatsappMessageReset,

    WhatsappIntegratedRequesting,
    WhatsappIntegratedSuccess,
    WhatsappIntegratedError,
    WhatsappIntegratedReset,

    WhatsappConfigurationRequesting,
    WhatsappConfigurationSuccess,
    WhatsappConfigurationError,
    WhatsappConfigurationReset,

    WhatsappCreateConfigurationRequesting,
    WhatsappCreateConfigurationSuccess,
    WhatsappCreateConfigurationError,
    WhatsappCreateConfigurationReset,

    WhatsappDeleteConfigurationRequesting,
    WhatsappDeleteConfigurationSuccess,
    WhatsappDeleteConfigurationError,
    WhatsappDeleteConfigurationReset,

    WhatsappUpdateConfigurationRequesting,
    WhatsappUpdateConfigurationSuccess,
    WhatsappUpdateConfigurationError,
    WhatsappUpdateConfigurationReset,
    WhatsappMessageIncoming,

    WhatsappAnalyticsRequesting,
    WhatsappAnalyticsSuccess,
    WhatsappAnalyticsError,
    WhatsappAnalyticsReset,

    WhatsappBulkRequesting,
    WhatsappBulkSuccess,
    WhatsappBulkError,
    WhatsappBulkReset,

    WhatsappUnidentifiedSenderRequesting,
    WhatsappUnidentifiedSenderSuccess,
    WhatsappUnidentifiedSenderError,
    WhatsappUnidentifiedSenderReset,
    WhatsappUnidentifiedSenderMarkASRead,

    DeleteWhatsappUnidentifiedSenderRequesting,
    DeleteWhatsappUnidentifiedSenderSuccess,
    DeleteWhatsappUnidentifiedSenderError,
    DeleteWhatsappUnidentifiedSenderReset,

    WhatsappCreateTemplateRequesting,
    WhatsappCreateTemplateSuccess,
    WhatsappCreateTemplateError,
    WhatsappCreateTemplateReset,

    WhatsappDeleteTemplateRequesting,
    WhatsappDeleteTemplateSuccess,
    WhatsappDeleteTemplateError,
    WhatsappDeleteTemplateReset,

    WhatsappBulkStatusRequesting,
    WhatsappBulkStatusSuccess,
    WhatsappBulkStatusReset,
    WhatsappBulkStatusError,

    EmailUnidentifiedSenderRequesting,
    EmailUnidentifiedSenderSuccess,
    EmailUnidentifiedSenderReset,
    EmailUnidentifiedSenderError,
    EmailUnidentifiedSenderMarkASRead,


    CreateChatBotRequesting,
    CreateChatBotSuccess,
    CreateChatBotError,
    CreateChatBotReset,


    ListChatBotRequesting,
    ListChatBotSuccess,
    ListChatBotError,
    ListChatBotReset,

    DeleteChatBotRequesting,
    DeleteChatBotSuccess,
    DeleteChatBotError,
    DeleteChatBotReset,

    FetchChatBotRequesting,
    FetchChatBotSuccess,
    FetchChatBotError,
    FetchChatBotReset,

    UpdateChatBotRequesting,
    UpdateChatBotSuccess,
    UpdateChatBotError,
    UpdateChatBotReset,

    WhatsappResetState

} = WhatsappSlice.actions;
export const WhatsappState = (state) => state.whatsapp
export default WhatsappSlice.reducer